import React, { useState } from 'react';
import { BiHome, BiShoppingBag, BiInfoCircle, BiBox, BiCustomize, BiPlanet, BiCoin, BiBarChart, BiPoll, BiCaretLeft, BiPieChart, BiPieChartAlt2, BiShapePolygon, BiCalculator, BiCylinder, BiSolidCylinder, BiCoinStack, BiSolidCoin, BiWallet, BiSupport, BiLineChart} from 'react-icons/bi';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Logo from './logo.svg';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import ConnectButton from './components/ConnectButton';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react';
import Dashboard from './components/Dashboard';
import Nodes from './components/Nodes';
import Staking from './components/Staking';
import StakingGlobal from './components/StakingGlobal';
import Affiliate from './components/Affiliate';
import Info from './components/Info';
import NodeInfo from './components/NodeInfo';
import NodeMergeSection from './components/NodeMergeSection';
import QvrseLore from './components/QvrseLore';
import FAQs from './components/FAQs';
import Footer from './components/Footer';
import FlipNotification from './components/FlipNotification';
import TokenSale from './components/TokenSale';
import NodesList from './components/NodesList';
import MarketCap from './components/MarketCap';
import Tokens from './components/Tokens';

function App() {
  const { address, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [expanded, setExpanded] = useState(false);

  const handleLinkClick = () => {
    setExpanded(false);
  };

  return (
    <Router>
        <div className="flip-notification-wrapper">
          <FlipNotification />
        </div>
        <Navbar expand="lg" className="top-bar" expanded={expanded}>
          <Container>
              <Navbar.Brand as={Link} to="/" onClick={handleLinkClick}>
                  <img src={Logo} style={{ width: "140px" }} alt={"Logo"} />
              </Navbar.Brand>

              {/* Hamburger Toggle */}
              <Navbar.Toggle 
                  aria-controls="basic-navbar-nav" 
                  onClick={() => setExpanded(expanded ? false : 'expanded')} 
                  className='px-0' 
              />

              <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto mt-lg-0 mt-4 navbar-bkg">
                      {/* Connect Button in the dropdown for small screens */}
                      <div className="d-block d-sm-block d-md-none">
                          <NavDropdown title="Connect" id="connect-dropdown" className="menuBtn dropdown-padding">
                              {/* Connect Button positioned at the top of the dropdown */}
                              <NavDropdown.Item onClick={() => { setExpanded(false); }}>
                                  <ConnectButton />
                              </NavDropdown.Item>
                          </NavDropdown>
                      </div>

                      <Nav.Link as={Link} to="/" className="menuBtn" onClick={handleLinkClick}> Home</Nav.Link>

                      <NavDropdown title={<span> Staking</span>} id="basic-nav-dropdown" className="menuBtn dropdown-padding">
                          <NavDropdown.Item>
                              <Nav.Link as={Link} to="/staking" onClick={handleLinkClick}><span><BiCoinStack /> Stake WAVE</span></Nav.Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item>
                              <Nav.Link as={Link} to="/staking-gbl" onClick={handleLinkClick}><span><BiCoinStack /> Stake GBL</span></Nav.Link>
                          </NavDropdown.Item>
                      </NavDropdown>

                      <NavDropdown title={<span> Nodes</span>} id="basic-nav-dropdown" className="menuBtn dropdown-padding">
                          <NavDropdown.Item>
                              <Nav.Link as={Link} to="/store" onClick={handleLinkClick}><span><BiBarChart /> Market</span></Nav.Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item>
                              <Nav.Link as={Link} to="/craft" onClick={handleLinkClick}><span><BiCustomize /> Crafting</span></Nav.Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item>
                              <Nav.Link as={Link} to="/node-info" onClick={handleLinkClick}><span><BiSolidCylinder /> About Nodes </span></Nav.Link>
                          </NavDropdown.Item>
                      </NavDropdown>

                      <NavDropdown title={<span>Account</span>} id="basic-nav-dropdown" className="menuBtn dropdown-padding">
                          <NavDropdown.Item>
                              <Nav.Link as={Link} to="/list" onClick={handleLinkClick}><span><BiPieChartAlt2 /> Portfolio</span></Nav.Link>
                          </NavDropdown.Item>
                      </NavDropdown>

                      <NavDropdown title={<span>Info</span>} id="basic-nav-dropdown" className="menuBtn dropdown-padding">
                          <NavDropdown.Item>
                              <Nav.Link as={Link} to="/tokens" onClick={handleLinkClick}><span><BiLineChart /> Tokens</span></Nav.Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item>
                              <Nav.Link as={Link} to="/node-info" onClick={handleLinkClick}><span><BiSolidCylinder /> About Nodes </span></Nav.Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item>
                              <Nav.Link as={Link} to="/FAQs" onClick={handleLinkClick}><span><BiSupport /> FAQs</span></Nav.Link>
                          </NavDropdown.Item>
                      </NavDropdown>
                  </Nav>
              </Navbar.Collapse>

              {/* Connect Button for larger screens */}
              <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block">
                  <ConnectButton />
              </div>
          </Container>
      </Navbar>




      <Container className='pb-5'>
        <Routes>
          <Route path="/FAQs" element={<FAQs />} />
          <Route path="/lore" element={<QvrseLore />} />
          <Route path="/info" element={<Info provider={walletProvider} />} />
          <Route path="/node-info" element={<NodeInfo provider={walletProvider} />} />
          <Route path="/staking" element={<Staking provider={walletProvider} account={address} isConnected={isConnected} />} />
          <Route path="/staking-gbl" element={<StakingGlobal provider={walletProvider} account={address} isConnected={isConnected} />} />
          <Route path="/affiliate" element={<Affiliate provider={walletProvider} account={address} isConnected={isConnected} />} />
          <Route path="/store" element={<Nodes provider={walletProvider} account={address} isConnected={isConnected} />} />
          <Route path="/craft" element={<NodeMergeSection provider={walletProvider} account={address} isConnected={isConnected} />} />
          <Route path="/token" element={<TokenSale provider={walletProvider} account={address} isConnected={isConnected} />} />
          <Route path="/tokens" element={<Tokens provider={walletProvider} account={address} isConnected={isConnected} />} />
          <Route path="/" element={<Dashboard provider={walletProvider} address={address} isConnected={isConnected} />} />
          <Route path="/list" element={<NodesList provider={walletProvider} address={address} isConnected={isConnected} />} />
        </Routes>
      </Container>
      <Footer />
    </Router>
  );
}

export default App;