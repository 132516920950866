import React from 'react';
import { Row, Col, Button, Card } from 'react-bootstrap';
import { BiLogoTelegram, BiLineChart} from "react-icons/bi";
import SETTINGS from '../SETTINGS';

const Affiliate = () => {
  const affiliate = Object.values(SETTINGS.AFFILIATE_INFO);

  return (
    <div className="container">
      {/* Title */}
      <h2 className='pageTitle'>AFFILIATES</h2>

      {/* Welcome */}
      <div>
        <p className='pageWelcome'>WaveWallet offers a decentralized crypto environment that seamlessly integrates real asset tokenization, token liquidity unlocking, and community-driven decision-making with smart-ownership features. The platform is designed to be transparent, simple, and user-friendly. </p> 
        <p className='pageWelcome'>In addition, third-party affiliates provide sustainable income-earning opportunities, with a portion of the earnings contributing to shareholding rewards for holders of the WSHARE token. This token plays a crucial role in the ecosystem's governance and management.</p>
        <p className='pageWelcome'>All results are presented live, sourced from independent global resources. Distributions are processed on the 1st of each month, based on the previous month's accumulation.</p>
      </div>

      <div className='affiliate-container'>
        <p className='affiliateFee'><small>Waveswaps FEE: </small> <span><b>30,000 USDC</b></span></p>
        <p className='affiliateFee'><small>Affiliates FEE:  </small><span><b>30,000 USDC</b></span></p>
        <p className='affiliateFee'><small>WSHARE sales: </small> <span><b>30,000 USDC</b></span></p>
      </div>

      <p className='pageWelcome'><u>Distribution reports:</u></p>
      <Button 
                  variant="primary buton-secondary" 
                  className='buton'
                  style={{ width: '196px' }} 
                >
                  <BiLineChart className='me-1' /> Become Shareholder
                </Button>
      <p className='pageWelcome mt-4'><u>WaveWallet Affiliates:</u></p>

      <Row className="card-container mt-5">
        {affiliate.map((engine, index) => (
          <Col xs={12} md={6} lg={4} key={index} className="mb-4">
            <Card className="engine-card">
              <Row className="align-items-center">
                <Col xs={6}>
                  <img
                    src={engine.logo}
                    alt={`${engine.alias} logo`}
                    className="engine-logo rounded"
                  />
                </Col>
                <Col xs={6} className="engine-alias">
                  <p className="mb-1"><strong>{engine.alias}</strong></p>
                  <a href={engine.address} target="_blank" rel="noopener noreferrer">
                    {engine.address}
                  </a>
                  <p className="mb-1 mt-2">
                    <a href={engine.telegram} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <BiLogoTelegram className='me-1' style={{ color:'#0dcaf0' }}></BiLogoTelegram> Telegram
                    </a>
                  </p>
                </Col>
              </Row>
              <Row className="mt-2"> {/* Margin-top removed to align directly under the first row */}
                <Col>
                  <p className="engine-description">{engine.description}</p>
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  <Button href={engine.referral} className="buton mb-3 mt-2 btn btn-primary btn btn-primary">Join</Button>
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Affiliate;
