
const SETTINGS = {
    nodeContract:"0xd828E1B453c4DaC3F78A136EC70b554eFD10b1fd",
    //nodeContract:"0xf752FD5abE8c08A829E606cbf56407140cfEd306",
    ///nodeContract:"0xc08b21627A109CBb6BA56cA351b5A6Fe135EcD67",
    //nodeContract:"0xa6A7F2619409bD2F82E152440bE9f1C440c00D64",
    
    tokenSaleAddress:"0x093eF8ad83AbC9e22C7c2c19F17b4cb11c837CF2",
    mainContract:"0xd46928C9E6A653A6c1C6Bccfc75eE9De15Ec8Eea",
    //mainContract:"0xf9f377cb1FBCaE718F6E6b7b4A5bde7f6de0c48A",
    usdtAddress:"0x1E2A7824942754c30D8a24C1de8B383d417b804d",
    //tokenAddress:"0x14d1996D29616C1fb589522ce6A81a583f1c32bE",
    tokenAddress:"0x1E2A7824942754c30D8a24C1de8B383d417b804d",
    globalTokenAddress:"0x089F268462474D409Cf5ecC553B2Adaf40E82AF7",
    stakingContract:"0x7D7150d81F108a3C7fAeA821610e5b0Bf7Fbf628",
    globalStakingContract:"0x0e1744A5787e8C059FD507FC891ADb7B6334e952",
    //stakingContract:"0x5aAEBb9543725F94785a3945b35319aD275aEc67",
    //stakingContract:"0x84DBe5DdD187eA681E3528BAB49C023Ef05072a2",

    nativeSymbol: "POL",
    nativeIcon: "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
    usdtIcon: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
    gblSymbol: "GBL",
    tokenSymbol: "WAVE",
    collectionSymbol: "WAVEND",
    openSeaLink: "",
    spatialLink: "",
    collection: "0x5Df7FF63e5e35E438cB8D7B3E2082DA97b48Db1C",
    MAX_SUPPLY: 20000,
    AP_PRICE: 1,
    END_TIME: 1843121631 * 1000,
    NEXT_AIRDROP: 1725174000 * 1000,
    NODE_INFO:{
        "1":{
                airdrop_power: 1,
                title: 'WAVE', 
                sort: 'NODE',
                level: 'WAVE NODE', 
                lifespan:'1000',
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.waveswaps.com/node.png", 
                craftElements:[],
                price: 100,
                buyCurrency:"WAVE",
                nodeCurrency:"WAVE",
                hoverText: "WAVE NODE",
                total: 99999999
            },
        "2":{
                airdrop_power: 2,
                title: 'CHRONOS', 
                sort: 'NODE',
                level: 'CHRONOS NODE', 
                lifespan:'300',
                dailyRewards: 1, 
                refferalPercent: 1, 
                imgUrl: "https://node.waveswaps.com/node1.png", 
                craftElements:["https://node.waveswaps.com/node.png", "https://node.waveswaps.com/node70.png"],
                price: 110,
                buyCurrency:"",
                nodeCurrency:"WAVE",
                hoverText: "CHRONOS NODE",
                total: 2718
            },
        "3":{
                airdrop_power: 3,
                title: 'STELLAR',
                sort: 'NODE',
                level: 'STELLAR NODE',
                lifespan:'90',
                dailyRewards: 3, 
                refferalPercent: 3,
                imgUrl: "https://node.waveswaps.com/node2.png", 
                craftElements:["https://node.waveswaps.com/node1.png", "https://node.waveswaps.com/node70.png"],
                price: 120,
                buyCurrency:"",
                nodeCurrency:"WAVE",
                hoverText: "STELLAR NODE",
                total: 8154
            },
        "4":{
                airdrop_power: 4,
                title: 'ECLIPSE', 
                sort: 'NODE',
                level: 'ECLIPSE NODE', 
                lifespan:'60',
                dailyRewards: 4.5, 
                refferalPercent: 4.5, 
                imgUrl: "https://node.waveswaps.com/node3.png", 
                craftElements:["https://node.waveswaps.com/node2.png", "https://node.waveswaps.com/node70.png"],
                price: 150,
                buyCurrency:"",
                nodeCurrency:"WAVE",
                hoverText: "ECLIPSE NODE",
                total: 16308
            },
        "5":{
                airdrop_power: 5,
                title: 'QUANTUM', 
                sort: 'NODE',
                level: 'QUANTUM NODE',  
                lifespan:'30',
                dailyRewards: 9, 
                refferalPercent: 6, 
                imgUrl: "https://node.waveswaps.com/node4.png", 
                craftElements:["https://node.waveswaps.com/node3.png", "https://node.waveswaps.com/node70.png"],
                price: 190,
                buyCurrency:"",
                nodeCurrency:"WAVE",
                hoverText: "QUANTUM NODE",
                total: 24462
            },
        "6":{
                airdrop_power: 6,
                title: 'GLOBALIS', 
                sort: 'NODE',
                level: 'GLOBALIS NODE', 
                lifespan:'9',
                dailyRewards: 30, 
                refferalPercent: 9, 
                imgUrl: "https://node.waveswaps.com/node5.png",  
                craftElements:["https://node.waveswaps.com/node4.png", "https://node.waveswaps.com/node70.png"],
                price: 240,
                buyCurrency:"",
                nodeCurrency:"WAVE",
                hoverText: "GLOBALIS NODE",
                total: 81540
            },
        "LOAN":{
                airdrop_power: 0,
                title: 'LOAN NODE', 
                level: 'LOAN NODE', 
                lifespan: '30',
                dailyRewards: 9, 
                refferalPercent: 0, 
                imgUrl: "https://wave.waveswaps.com/img/globalis.webp", 
                craftElements:["https://wave.waveswaps.com/img/quantum.webp", "https://wave.waveswaps.com/img/grosh.webp"],
                price: 270,
                buyCurrency:"WAVE",
                hoverText: "LOAN NODE"
            },
        "BTC":{
                airdrop_power: 0,
                title: 'BTC NODE', 
                level: 'BTC NODE', 
                lifespan:'30',
                dailyRewards: 9, 
                refferalPercent: 9, 
                imgUrl: "https://wave.waveswaps.com/img/btc.webp", 
                craftElements:["https://wave.waveswaps.com/img/quantum.webp", "https://wave.waveswaps.com/img/grosh.webp"],
                price: 0.000001,
                buyLogo: "https://wave.waveswaps.com/img/assets/btc.svg", 
                buyCurrency:"BTC",
                hoverText: "BTC NODE"
            },
        "ETH":{
                airdrop_power: 0,
                title: 'ETH NODE', 
                level: 'ETH NODE', 
                lifespan:'30',
                dailyRewards: 9, 
                refferalPercent: 9, 
                imgUrl: "https://wave.waveswaps.com/img/eth.webp", 
                craftElements:["https://wave.waveswaps.com/img/quantum.webp", "https://wave.waveswaps.com/img/grosh.webp", "https://wave.waveswaps.com/img/pron.webp"],
                price: 0.000123,
                buyLogo: "https://wave.waveswaps.com/img/assets/eth.svg", 
                buyCurrency:"ETH",
                hoverText: "ETH NODE"
            },
        "XRP":{
                airdrop_power: 0,
                title: 'XRP NODE', 
                level: 'XRP NODE', 
                lifespan:'30',
                dailyRewards: 9, 
                refferalPercent: 9, 
                imgUrl: "https://wave.waveswaps.com/img/xrp.webp", 
                craftElements:["https://wave.waveswaps.com/img/quantum.webp", "https://wave.waveswaps.com/img/grosh.webp", "https://wave.waveswaps.com/img/pron.webp"],
                price: 100.0001,
                buyLogo: "https://wave.waveswaps.com/img/assets/xrp.svg", 
                buyCurrency:"XRP",
                hoverText: "XRP NODE"
            },
        "SOL":{
                airdrop_power: 0,
                title: 'SOL NODE', 
                level: 'SOL NODE', 
                lifespan:'30',
                dailyRewards: 9, 
                refferalPercent: 9, 
                imgUrl: "https://wave.waveswaps.com/img/sol.webp", 
                craftElements:["https://wave.waveswaps.com/img/quantum.webp", "https://wave.waveswaps.com/img/grosh.webp", "https://wave.waveswaps.com/img/pron.webp"],
                price: 10.0001,
                buyLogo: "https://wave.waveswaps.com/img/assets/sol.svg", 
                buyCurrency:"SOL",
                hoverText: "SOL NODE"
            },
        "10":{
                airdrop_power: 0,
                title: 'PIPL',
                sort: 'POWER CORE',
                lifespan:'300', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.waveswaps.com/node10.png",
                craftElements:[],
                price: 10,
                buyCurrency:"PIPL",
                hoverText: "PIPL POWER CORE",
                buyToken:"0x13aD23c7009a54CFa87Fe5566F518eB59F4431Db",
                logoToken:"https://logos.waveswaps.com/assets/PIPL.svg"
            },
        "20":{
                airdrop_power: 0,
                title: 'GETON', 
                lifespan:'300',
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.waveswaps.com/node20.png",  
                craftElements:[],
                price: 0.1,
                buyCurrency:"GETON",
                hoverText: "GETON POWER CORE",
                buyToken:"0xB751839C4e016f0AC2d73A109B5247d8F9f29998",
                logoToken:"https://logos.waveswaps.com/assets/GETON.svg"
            },
        "30":{
                airdrop_power: 0,
                title: 'GROSH', 
                lifespan:'300',
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.waveswaps.com/node30.png", 
                craftElements:[],
                price: 10,
                buyCurrency:"GROSH",
                hoverText: "GROSH POWER CORE",
                buyToken:"0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                logoToken:"https://logos.waveswaps.com/assets/GROSH.svg"
            },
        "40":{
                airdrop_power: 0,
                title: 'CODE', 
                lifespan:'300',
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.waveswaps.com/node40.png", 
                craftElements:[],
                price: 10,
                buyCurrency:"CODE",
                hoverText: "CODE POWER CORE",
                buyToken:"0x88f648c7976E4571E3823bA0b8598A72A9ac94b5",
                logoToken:"https://logos.waveswaps.com/assets/CODE.svg"
            },
            
        "50":{
                airdrop_power: 0,
                title: 'META', 
                lifespan:'300',
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.waveswaps.com/node50.png", 
                craftElements:[],
                price: 10,
                buyCurrency:"META",
                hoverText: "META POWER CORE",
                buyToken:"0x218600CcC2B781eA3170d087bCFEe22Fc5A50747",
                logoToken:"https://logos.waveswaps.com/assets/META.svg"
            },

        "60":{
                airdrop_power: 0,
                title: 'PRON', 
                lifespan:'300',
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.waveswaps.com/node60.png", 
                craftElements:[],
                price: 0.01,
                buyCurrency:"PRON",
                hoverText: "PRON POWER CORE",
                buyToken:"0x2b277782ca8e19d490a470ca43087819b4baa5f2",
                logoToken:"https://logos.waveswaps.com/assets/PRON.svg"
            },
        "70":{
                airdrop_power: 0,
                title: 'GBL', 
                lifespan:'300',
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.waveswaps.com/node70.png", 
                craftElements:[],
                price: 1,
                buyCurrency:"GBL",
                hoverText: "GBL POWER CORE",
                buyToken:"0x089F268462474D409Cf5ecC553B2Adaf40E82AF7",
                logoToken:"https://logos.waveswaps.com/assets/GBL.svg"
            },
        "80":{
                airdrop_power: 0,
                title: 'WAVE', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://wave.waveswaps.com/img/wave.webp", 
                craftElements:[],
                price: 10,
                buyCurrency:"WAVE",
                hoverText: "WAVE",
                buyToken:"0x1E2A7824942754c30D8a24C1de8B383d417b804d",
                logoToken:"https://logos.waveswaps.com/assets/WAVE.svg"
            },
        "18":{
                airdrop_power: 0,
                title: 'BTC', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://wave.waveswaps.com/img/btc.webp", 
                craftElements:[],
                price: 10,
                buyCurrency:"BTC",
                hoverText: "BTC"
            },
        "19":{
                airdrop_power: 0,
                title: 'ETH', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://wave.waveswaps.com/img/eth.webp", 
                craftElements:[],
                price: 10,
                buyCurrency:"ETH",
                hoverText: "ETH"
            },
        "14":{
                airdrop_power: 0,
                title: 'XRP', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://wave.waveswaps.com/img/xrp.webp", 
                craftElements:[],
                price: 10,
                buyCurrency:"XRP",
                hoverText: "XRP"
            },
        "21":{
                airdrop_power: 0,
                title: 'SOL', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://wave.waveswaps.com/img/sol.webp", 
                craftElements:[],
                price: 10,
                buyCurrency:"SOL",
                hoverText: "SOL"
            },
            
       
    },

    AFFILIATE_INFO: {
        "SAGEMASTER": {
            logo: "https://wave.waveswaps.com/img/affiliate/sagemaster.webp",
            alias: "SAGEMASTER",
            address: "SageMaster",
            telegram: "link",
            description: "Uniswap is a decentralized exchange (DEX) allowing users to swap various cryptocurrencies.",
            referral: "https://uniswap.org/referral_link"
        },
        "UNISWAP": {
            logo: "https://wave.waveswaps.com/img/affiliate/uniswap.svg",
            alias: "Uniswap DEX",
            address: "UniSwap",
            telegram: "link",
            description: "Uniswap is a decentralized exchange (DEX) allowing users to swap various cryptocurrencies.",
            referral: "https://uniswap.org/referral_link"
        },
        "SUSHISWAP": {
            logo: "https://wave.waveswaps.com/img/affiliate/sushiswap.svg",
            alias: "SushiSwap DEX",
            address: "SushiSwap",
            telegram: "link",
            description: "SushiSwap is a community-run DEX offering diverse DeFi services. SushiSwap is a community-run DEX offering diverse DeFi services. SushiSwap is a community-run DEX offering diverse DeFi services. SushiSwap is a community-run DEX offering diverse DeFi services. SushiSwap is a community-run DEX offering diverse DeFi services. SushiSwap is a community-run DEX offering diverse DeFi services. SushiSwap is a community-run DEX offering diverse DeFi ",
            referral: "https://sushi.com/referral_link"
        },
        "WAWESWAPS": {
            logo: "https://wave.waveswaps.com/img/affiliate/wshare.svg",
            alias: 'WAVESWAPS FEE',
            address: 'WaveSwaps',
            telegram: "link",
            description: (
              <span>
                Lorem ipsum, lorem ipsum Learn more about Uniswap’s functionality by visiting the{' '}
                <a href="https://docs.uniswap.org/" target="_blank" rel="noopener noreferrer">
                  official documentation
                </a>.
              </span>
            ),
            referral: 'https://uniswap.org/referral'
          }
        // Add more affilaites as needed...
    },

    ASSETS: {

        WaveCoin: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/WAVE.svg",
        chainExplorer: "https://amoy.polygonscan.com/token/0x1E2A7824942754c30D8a24C1de8B383d417b804d",
        name: "WaveCoin",
        symbol: "WAVE",
        tokenAddress1: '0x1E2A7824942754c30D8a24C1de8B383d417b804d',
        faucetSupply:'0x1281b4863da7cee206b2b073dbe484bff599f934',
        faucet: "https://faucet.waveswaps.com/wave/index.html",
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 6,   // Decimals for tokenAddress2
        totalSupply: 1000000,
        chainId: 80002,  // Polygon Mainnet chain ID
        network: 'Polygon Amoy Testnet',  // Name of the network
        rpcUrl: 'https://rpc-amoy.polygon.technology',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://amoy.polygonscan.com/',  // Block explorer URL
      },
      GlobalToken: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/GBL.svg",
        chainExplorer: "https://amoy.polygonscan.com/token/0x089F268462474D409Cf5ecC553B2Adaf40E82AF7",
        name: "GlobalToken",
        symbol: "GBL",
        tokenAddress1: '0x089F268462474D409Cf5ecC553B2Adaf40E82AF7',
        faucetSupply:'0x71f53a87450431ac38f0635b40323cd847785b34',
        faucet: "https://faucet.waveswaps.com/gbl/index.html",
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 6,   // Decimals for tokenAddress2
        totalSupply: 17900000,
        chainId: 80002,  // Polygon Mainnet chain ID
        network: 'Polygon Amoy Testnet',  // Name of the network
        rpcUrl: 'https://rpc-amoy.polygon.technology',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://amoy.polygonscan.com/',  // Block explorer URL
      },
      MetaCoin: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/META.svg",
        chainExplorer: "https://amoy.polygonscan.com/token/0x218600CcC2B781eA3170d087bCFEe22Fc5A50747",
        name: "MetaCoin",
        symbol: "META",
        tokenAddress1: '0x218600CcC2B781eA3170d087bCFEe22Fc5A50747',
        faucetSupply:'0x51c0c6fc903c1d6ed3e220b609e03fe434ed47ee',
        faucet: "https://faucet.waveswaps.com/meta/index.html",
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 6,   // Decimals for tokenAddress2
        totalSupply: 210000000,
        chainId: 80002,  // Polygon Mainnet chain ID
        network: 'Polygon Amoy Testnet',  // Name of the network
        rpcUrl: 'https://rpc-amoy.polygon.technology',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://amoy.polygonscan.com/',  // Block explorer URL
      },
      CryptoCode: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/CODE.svg",
        chainExplorer: "https://amoy.polygonscan.com/token/0x88f648c7976E4571E3823bA0b8598A72A9ac94b5",
        name: "CryptoCode",
        symbol: "CODE",
        tokenAddress1: '0x88f648c7976E4571E3823bA0b8598A72A9ac94b5',
        faucetSupply:'0x59b14c2bad4ebe63e24a7307b66a743d8685fb17',
        faucet: "https://faucet.waveswaps.com/code/index.html",
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 6,   // Decimals for tokenAddress2
        totalSupply: 21000000,
        chainId: 80002,  // Polygon Mainnet chain ID
        network: 'Polygon Amoy Testnet',  // Name of the network
        rpcUrl: 'https://rpc-amoy.polygon.technology',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://amoy.polygonscan.com/',  // Block explorer URL
      },
      PiplCoin: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/PIPL.svg",
        chainExplorer: "https://amoy.polygonscan.com/token/0x13aD23c7009a54CFa87Fe5566F518eB59F4431Db",
        name: "PiplCoin",
        symbol: "PIPL",
        tokenAddress1: '0x13aD23c7009a54CFa87Fe5566F518eB59F4431Db',
        faucetSupply:'0x5350cd85710a6c48107e031483bc5a9739a18447',
        faucet: "https://faucet.waveswaps.com/pipl/index.html",
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 6,   // Decimals for tokenAddress2
        totalSupply: 261797900,
        chainId: 80002,  // Polygon Mainnet chain ID
        network: 'Polygon Amoy Testnet',  // Name of the network
        rpcUrl: 'https://rpc-amoy.polygon.technology',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://amoy.polygonscan.com/',  // Block explorer URL
      },
      GroshCoin: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/GROSH.svg",
        chainExplorer: "https://amoy.polygonscan.com/token/0xA63Cb1F1d6a787a35B5285B709B761917e933642",
        name: "GroshCoin",
        symbol: "GROSH",
        tokenAddress1: '0xA63Cb1F1d6a787a35B5285B709B761917e933642',
        faucetSupply:'0x0d8b1125f257437e1424e3f93463f734f4009a24',
        faucet: "https://faucet.waveswaps.com/grosh/index.html",
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 6,   // Decimals for tokenAddress2
        totalSupply: 210000000,
        chainId: 80002,  // Polygon Mainnet chain ID
        network: 'Polygon Amoy Testnet',  // Name of the network
        rpcUrl: 'https://rpc-amoy.polygon.technology',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://amoy.polygonscan.com/',  // Block explorer URL
      },
      GetonCoin: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/GETON.svg",
        chainExplorer: "https://amoy.polygonscan.com/token/0xB751839C4e016f0AC2d73A109B5247d8F9f29998",
        name: "GetonCoin",
        symbol: "GETON",
        tokenAddress1: '0xB751839C4e016f0AC2d73A109B5247d8F9f29998',
        faucetSupply:'0xfa2b835e6dd883ca38fe6cd9a485762c29c073ad',
        faucet: "https://faucet.waveswaps.com/geton/index.html",
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 6,   // Decimals for tokenAddress2
        totalSupply: 1542000000,
        chainId: 80002,  // Polygon Mainnet chain ID
        network: 'Polygon Amoy Testnet',  // Name of the network
        rpcUrl: 'https://rpc-amoy.polygon.technology',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://amoy.polygonscan.com/',  // Block explorer URL
      },
      PronCoin: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/PRON.svg",
        chainExplorer: "https://amoy.polygonscan.com/token/0x2b277782Ca8e19d490a470CA43087819b4BaA5F2",
        name: "PronCoin",
        symbol: "PRON",
        tokenAddress1: '0x2b277782Ca8e19d490a470CA43087819b4BaA5F2',
        faucetSupply:'0x7d968b1b00a9872e9e22332b190031b8124f69bb',
        faucet: "https://faucet.waveswaps.com/pron/index.html",
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 6,   // Decimals for tokenAddress2
        totalSupply: 69000000,
        chainId: 80002,  // Polygon Mainnet chain ID
        network: 'Polygon Amoy Testnet',  // Name of the network
        rpcUrl: 'https://rpc-amoy.polygon.technology',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://amoy.polygonscan.com/',  // Block explorer URL
      },
      
      // Add other assets similarly...
    },

    CRAFT_DICT:{
        "1_10_0_0": ["https://wave.waveswaps.com/img/chronos.webp", 2],
        "1_20_0_0": ["https://wave.waveswaps.com/img/chronos.webp", 2],
        "1_30_0_0": ["https://wave.waveswaps.com/img/chronos.webp", 2],
        "1_40_0_0": ["https://wave.waveswaps.com/img/chronos.webp", 2],
        "1_50_0_0": ["https://wave.waveswaps.com/img/chronos.webp", 2],
        "1_60_0_0": ["https://wave.waveswaps.com/img/chronos.webp", 2],
        "1_70_0_0": ["https://wave.waveswaps.com/img/chronos.webp", 2],
        "1_80_0_0": ["https://wave.waveswaps.com/img/chronos.webp", 2],

        "2_10_0_0": ["https://wave.waveswaps.com/img/stellar.webp", 3],
        "2_20_0_0": ["https://wave.waveswaps.com/img/stellar.webp", 3],
        "2_30_0_0": ["https://wave.waveswaps.com/img/stellar.webp", 3],
        "2_40_0_0": ["https://wave.waveswaps.com/img/stellar.webp", 3],
        "2_50_0_0": ["https://wave.waveswaps.com/img/stellar.webp", 3],
        "2_60_0_0": ["https://wave.waveswaps.com/img/stellar.webp", 3],
        "2_70_0_0": ["https://wave.waveswaps.com/img/stellar.webp", 3],
        "2_80_0_0": ["https://wave.waveswaps.com/img/stellar.webp", 3],

        "3_10_0_0": ["https://wave.waveswaps.com/img/eclipse.webp", 4],
        "3_30_0_0": ["https://wave.waveswaps.com/img/eclipse.webp", 4],
        "3_40_0_0": ["https://wave.waveswaps.com/img/eclipse.webp", 4],
        "3_40_0_0": ["https://wave.waveswaps.com/img/eclipse.webp", 4],
        "3_50_0_0": ["https://wave.waveswaps.com/img/eclipse.webp", 4],
        "3_60_0_0": ["https://wave.waveswaps.com/img/eclipse.webp", 4],
        "3_70_0_0": ["https://wave.waveswaps.com/img/eclipse.webp", 4],
        "3_80_0_0": ["https://wave.waveswaps.com/img/eclipse.webp", 4],

        "4_10_0_0": ["https://wave.waveswaps.com/img/quantum.webp", 5],
        "4_40_0_0": ["https://wave.waveswaps.com/img/quantum.webp", 5],
        "4_50_0_0": ["https://wave.waveswaps.com/img/quantum.webp", 5],
        "4_50_0_0": ["https://wave.waveswaps.com/img/quantum.webp", 5],
        "4_50_0_0": ["https://wave.waveswaps.com/img/quantum.webp", 5],
        "4_60_0_0": ["https://wave.waveswaps.com/img/quantum.webp", 5],
        "4_70_0_0": ["https://wave.waveswaps.com/img/quantum.webp", 5],
        "4_80_0_0": ["https://wave.waveswaps.com/img/quantum.webp", 5],

        "5_10_0_0": ["https://wave.waveswaps.com/img/globalis.webp", 6],
        "5_50_0_0": ["https://wave.waveswaps.com/img/globalis.webp", 6],
        "5_60_0_0": ["https://wave.waveswaps.com/img/globalis.webp", 6],
        "5_60_0_0": ["https://wave.waveswaps.com/img/globalis.webp", 6],
        "5_60_0_0": ["https://wave.waveswaps.com/img/globalis.webp", 6],
        "5_60_0_0": ["https://wave.waveswaps.com/img/globalis.webp", 6],
        "5_70_0_0": ["https://wave.waveswaps.com/img/globalis.webp", 6],
        "5_80_0_0": ["https://wave.waveswaps.com/img/globalis.webp", 6],

      }


};
export default SETTINGS;
