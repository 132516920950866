import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Col, Card, Row, Table, Modal } from 'react-bootstrap';
import { FaInfoCircle, FaLock, FaCoins, FaChartPie, FaChartBar } from 'react-icons/fa';
import SETTINGS from '../SETTINGS';
import { ERC20_ABI } from "../abis/erc20";
import { BrowserProvider, Contract, formatUnits } from 'ethers';
import Tokenomics from '../qvrs-tokenomics.png';

ChartJS.register(ArcElement, Tooltip, Legend);

function TokenInformation({ tokenData }) {
    return (
        <Card className="mb-3 custom-card">
            <Card.Body>
                <Card.Title><FaInfoCircle className="me-2" />Token Information</Card.Title>
                <div className="table-responsive">
                    <Table striped bordered hover>
                        <tbody>
                            <tr>
                                <td><strong>Token Name:</strong></td>
                                <td>{tokenData.name}</td>
                            </tr>
                            <tr>
                                <td><strong>Token Symbol:</strong></td>
                                <td>{tokenData.symbol}</td>
                            </tr>
                            <tr>
                                <td><strong>Token Decimals:</strong></td>
                                <td>{tokenData.decimals}</td>
                            </tr>
                            <tr>
                                <td><strong>Total Supply:</strong></td>
                                <td>{tokenData.totalSupply} {SETTINGS.tokenSymbol}</td>
                            </tr>
                            <tr>
                                <td><strong>Price (USDC):</strong></td>
                                <td>{tokenData.price} USDC</td>
                            </tr>
                            <tr>
                                <td><strong>Market Cap (USD):</strong></td>
                                <td>{tokenData.marketCap} USD</td>
                            </tr>
                            <tr>
                                <td><strong>Contract Address:</strong></td>
                                <td><a href={`https://polygonscan.com/address/${tokenData.contractAddress}`} target='_blank' rel="noopener noreferrer">{tokenData.contractAddress}</a></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </Card.Body>
        </Card>
    );
}

function Contracts({ totalLocked, circulating, holderContracts }) {
    return (
        <Card className="mb-3 custom-card">
            <Card.Body>
                <Card.Title><FaLock className="me-2" />Contracts</Card.Title>
                <Card.Text>
                    <b>Max supply:</b> 40,000,000.00 {SETTINGS.tokenSymbol}<br />
                    <b>Total locked:</b> {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalLocked)} {SETTINGS.tokenSymbol}<br />
                    <b>Circulating:</b> {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(circulating)} {SETTINGS.tokenSymbol}
                </Card.Text>
                <div className="table-responsive">
                    <Table striped bordered hover className='mt-md-4 mt-2'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Balance</th>
                                <th>Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            {holderContracts.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.name}</td>
                                    <td>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.balance)} {SETTINGS.tokenSymbol}</td>
                                    <td><a href={`https://polygonscan.com/address/${item.address}`} target='_blank' rel="noopener noreferrer">{item.trunAddress}</a></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </Card.Body>
        </Card>
    );
}

const Info = ({ provider, isConnected }) => {
    const [holderContracts, setHolderContracts] = useState([]);
    const [totalLocked, setTotalLocked] = useState(0);
    const [circulating, setCirculating] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const tokenData = {
        name: 'WaveCoin',
        symbol: SETTINGS.tokenSymbol,
        decimals: 18,
        totalSupply: '1000000',
        price: '1.00',
        marketCap: '0.00',
        //contractAddress: '0x14d1996D29616C1fb589522ce6A81a583f1c32bE'
        contractAddress: '0x1E2A7824942754c30D8a24C1de8B383d417b804d'
    };

    const data = {
        labels: [
            'Founders',
            'Team',
            'Funding',
            'Staking',
            'QWRLD rewards',
            'Reserve',
            'Liquidity'
        ],
        datasets: [
            {
                data: [12.5, 7.5, 12.5, 12.5, 25, 20, 10],
                backgroundColor: [
                    'rgba(148, 77, 255, 0.9)',
                    'rgba(148, 77, 255, 0.8)',
                    'rgba(148, 77, 255, 0.7)',
                    'rgba(148, 77, 255, 0.6)',
                    'rgba(148, 77, 255, 0.5)',
                    'rgba(148, 77, 255, 0.4)',
                    'rgba(148, 77, 255, 0.3)'
                ],
                hoverBackgroundColor: [
                    'rgba(148, 77, 255, 1)',
                    'rgba(148, 77, 255, 0.9)',
                    'rgba(148, 77, 255, 0.8)',
                    'rgba(148, 77, 255, 0.7)',
                    'rgba(148, 77, 255, 0.6)',
                    'rgba(148, 77, 255, 0.5)',
                    'rgba(148, 77, 255, 0.4)'
                ],
                borderColor: 'transparent'
            }
        ]
    };

    const tokenDistributionData = [
        { category: 'Founders', tokens: '5 M', percent: '12.5 %' },
        { category: 'Team', tokens: '3 M', percent: '7.5 %' },
        { category: 'Funding', tokens: '5 M', percent: '12.5 %' },
        { category: 'Staking', tokens: '5 M', percent: '12.5 %' },
        { category: 'QWRLD rewards', tokens: '10 M', percent: '25 %' },
        { category: 'Reserve', tokens: '8 M', percent: '20 %' },
        { category: 'Liquidity', tokens: '4 M', percent: '10 %' }
    ];

    const holders = [
        { name: 'QWRLD', address: SETTINGS.nodeContract },
        { name: 'Staking', address: SETTINGS.stakingContract },
        { name: 'Funding 1', address: SETTINGS.tokenSaleAddress },
        { name: 'Airdops', address: "0x526c3d12648A0663fdB2Da0B1Ef30e4d19869858" },
        { name: 'Membership', address: SETTINGS.stakingContractQtech },
        { name: 'Founders', address: '0x2B238e39b1d7C8bbF524B170003C2EA67Fc5B371' },
        { name: 'Team', address: '0xd62F85743E12C748C46254f22118709f8219ce36' },
        { name: 'Liquidity', address: '0x229b65A37cA552Ef0f9585e2277aBCFefa8169f3' },
        { name: 'Reserve', address: '0xf0dc8FBBcC14D3A16ba4ba23c835f8525FB99a2b' },
    ];

    async function getBalances() {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const TokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
        let holdersArr = [];
        let allBalanceAmount = 0;
        for (let i = 0; i < holders.length; i++) {
            const balance = await TokenContract.balanceOf(holders[i].address);
            const trunAddress = `${holders[i].address.slice(0, 6)}...${holders[i].address.slice(-1 * (5))}`;
            holdersArr.push({
                name: holders[i].name,
                address: holders[i].address,
                trunAddress: trunAddress,
                balance: formatUnits(balance, 18)
            });
            allBalanceAmount += parseFloat(formatUnits(balance, 18));
        }
        setHolderContracts(holdersArr);
        setTotalLocked(allBalanceAmount.toFixed(2));
        setCirculating((40000000 - allBalanceAmount).toFixed(2));
    }

    useEffect(() => {
        if (!provider) return;
        getBalances();
    }, [provider]);

    return (
        <div className="info-container">
            <Row className="g-4">
                {/* Title */}
                <h3 className='pageTitle pt-4'>WAVECOIN ({SETTINGS.tokenSymbol}) INFO</h3>
                {/* Token info */}
                <Col xs={12} lg={7} className='mt-0'>
                    <TokenInformation tokenData={tokenData} />
                </Col>
                {/* Tokenomics */}
                <Col xs={12} lg={5} className='mt-md-0 mt-0'>
                    <Card className="mb-3 custom-card">
                        <Card.Body>
                            <Card.Title><FaCoins className="me-2" />{SETTINGS.tokenSymbol} Tokenomics</Card.Title>
                            <img
                                src={Tokenomics}
                                style={{ width: "100%", cursor: "pointer" }}
                                alt="Tokenomics"
                                onClick={() => setShowModal(true)}
                            />
                        </Card.Body>
                    </Card>
                </Col>
                {/* Pie chart */}
                <Col xs={12} lg={4} className='mt-md-3 mt-0'>
                    <Card className="mb-3 custom-card">
                        <Card.Body>
                            <Card.Title className='mb-3'><FaChartPie className="me-2" />{SETTINGS.tokenSymbol} token Distribution</Card.Title>
                            <Pie data={data} />
                        </Card.Body>
                    </Card>
                </Col>
                {/* Distribution  */}
                <Col xs={12} lg={8} className='mt-md-3 mt-0'>
                    <Card className="mb-3 custom-card">
                        <Card.Body>
                            <Card.Title><FaChartBar className="me-2" />{SETTINGS.tokenSymbol} token Distribution</Card.Title>
                            <Table striped bordered hover className='mb-4 mt-4'>
                                <thead>
                                    <tr>
                                        <th>Category</th>
                                        <th>Tokens</th>
                                        <th>Percent</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tokenDistributionData.map((token, index) => (
                                        <tr key={index}>
                                            <td>{token.category}</td>
                                            <td>{token.tokens}</td>
                                            <td>{token.percent}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
                {/* Contracts */}
                <Col xs={12} lg={8} className='mt-md-3 mt-0 mb-4'>
                    <Contracts totalLocked={totalLocked} circulating={circulating} holderContracts={holderContracts} />
                </Col>
            </Row>
            {/* Modal for displaying enlarged image */}
            <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <img src={Tokenomics} alt="Zoomed Tokenomics" style={{ width: '100%' }} />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Info;