import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaInstagram, FaTelegramPlane } from 'react-icons/fa';
import { BiRocket, BiCard} from 'react-icons/bi';
import { MdOutlineIntegrationInstructions } from "react-icons/md";
import { LuArrowLeftRight } from "react-icons/lu";


const TwitterIcon = () => (
  <svg className="twitter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="19" height="19">
    <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
  </svg>
);

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="justify-content-center">
          <Col className="text-center">
            <a href="https://t.me/waweswaps" target="_blank" rel="noopener noreferrer me" className="social-icon telegram">
              <FaTelegramPlane size={21} />
            </a>
            <a href="https://t.me/waveswaps" target="_blank" rel="noopener noreferrer me" className="social-icon telegram">
              <FaTelegramPlane size={21} />
            </a>
            <a href="https://twitter.com/waweswaps/" target="_blank" rel="noopener noreferrer me" className="social-icon twitter">
              <TwitterIcon />
            </a>
            <a href="https://x.com/waveswaps/" target="_blank" rel="noopener noreferrer me" className="social-icon twitter">
              <TwitterIcon />
            </a>
            <a href="https://waveswaps.com" target="_blank" rel="noopener noreferrer me" className="social-icon platform">
              <LuArrowLeftRight size={21} className='me-1' /> Swaps
            </a>
            <a href="https://docs.waveswaps.com/" target="_blank" rel="noopener noreferrer me" className="social-icon platform">
              <MdOutlineIntegrationInstructions size={20} className='me-1' /> Docs
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
