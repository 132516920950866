import React from 'react';

const Modal = ({ onClose }) => {
    return (
      <div className="modal-overlay">
        <div className="modal-content">
        <p className="chat-message pageTitle">Testing - Training<br/>Version</p>
        <p class="pageModal">This WaveSwaps Wallet version is for testing and training purposes only!<br/> Please be aware that no real assets are involved, and features may not be fully operational.<br/>
        We encourage you to explore these features and share your insights. Early testers who contribute to improving the platform will be eligible for rewards in the live version.<br/>
        With our <a href="https://faucet.waveswaps.com/index.html" target="_blank" rel="noopener noreferrer">faucet</a>, you can seamlessly receive test tokens through a smart contract-based process. Simply connect your MetaMask wallet and click the button to get started.
        </p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default Modal;
