import React from 'react';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SETTINGS from '../SETTINGS';



const FAQs = () => {
  const navigate = useNavigate();
  const openInfo = () => {
    navigate('/info');
  }
  return (
    <Container className="px-0 mt-0">
      <Row className="mb-5">
          <h3 className='pageTitle mb-4 pb-1'>Frequently Asked Questions</h3>
        <Col xs={12} className="mx-auto">
          <Row>
            <Col lg={6} xs={12}>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0" className="mb-2">
                  <Accordion.Header>About WaveCoin</Accordion.Header>
                  <Accordion.Body>
                  <p className='justify'>
                    WaveCoin ({SETTINGS.tokenSymbol}) is an ERC-20 standard token deployed on the Polygon network. Full details can be found on <a href="./tokens">Tokens</a>. It serves as the native token of the WaveSwaps platform, and it is only released into circulation through token revamp operations within the platform. The initial supply of 1 million tokens is dedicated to providing liquidity on decentralized exchanges (DEX).
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className="mb-2">
                  <Accordion.Header>How can I acquire {SETTINGS.tokenSymbol} tokens?</Accordion.Header>
                  <Accordion.Body>
                  <p className='justify'>
                    You can acquire {SETTINGS.tokenSymbol} tokens by purchasing them on a DEX, by using the platform’s direct buy on DEX, or by earning them through platform mechanisms such as airdrops and daily {SETTINGS.tokenSymbol} rewards.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className="mb-2">
                  <Accordion.Header>What is the role of {SETTINGS.tokenSymbol} tokens in the ecosystem?</Accordion.Header>
                  <Accordion.Body>
                  <p className='justify'>
                    {SETTINGS.tokenSymbol} tokens serve as the native currency of the WaveSwaps ecosystem. They are used for purchasing Liquidity Nodes, staking, and facilitating transactions for services and products within the platform. 
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className="mb-2">
                  <Accordion.Header>Can I stake all my {SETTINGS.tokenSymbol} tokens?</Accordion.Header>
                  <Accordion.Body>
                  <p className='justify'>
                    Yes, you can stake any portion of your {SETTINGS.tokenSymbol} tokens up to your full balance. The "Max" button in the staking interface allows you to automatically fill the staking amount with all available tokens. 
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5" className="mb-2">
                  <Accordion.Header>How are staking rewards calculated?</Accordion.Header>
                  <Accordion.Body>
                  <p className='justify'>
                  Staking rewards are based on the Annual Percentage Yield (APY), the amount of {SETTINGS.tokenSymbol} tokens staked, and the length of the staking period. Rewards accumulate over time and are available for claim at the end of the staking period.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" className="mb-2">
                  <Accordion.Header>How can I claim staking rewards?</Accordion.Header>
                  <Accordion.Body>
                  <p className='justify'>
                      Once your staking period ends, you can claim both your staked tokens and any earned rewards. Simply use the "CLAIM" button in the staking section to transfer the tokens and rewards back to your balance.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>                
                <Accordion.Item eventKey="14" className="mb-2">
                  <Accordion.Header>How does the referral system work?</Accordion.Header>
                  <Accordion.Body>
                  <p className='justify'>
                  The referral system rewards you with bonus {SETTINGS.tokenSymbol} tokens for every transaction made by users you've invited to the platform. When your referral makes a purchase, you earn a {SETTINGS.tokenSymbol} power core, which can enhance your platform rewards.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col lg={6} xs={12}>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="7" className="mb-2">
                  <Accordion.Header>What are Liquidity Nodes and PowerCores? </Accordion.Header>
                  <Accordion.Body>
                  <p className='justify'>
                  Liquidity Nodes and PowerCores are digital assets in the form of NFTs, which can be purchased and crafted through the WaveSwaps Wallet dApp. These assets are critical for advancing through platform levels and unlocking exclusive benefits. By acquiring Liquidity Nodes, you receive a designated amount of WaveCoin tokens and can start earning daily rewards. These rewards are vested, meaning they can be claimed daily, increasing your engagement and potential returns on the platform.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8" className="mb-2">
                  <Accordion.Header>What is a Craft Book?</Accordion.Header>
                  <Accordion.Body>
                  <p className='justify'>
                  The Craft Book is a catalog of digital assets, including Liquidity Nodes and PowerCores, which users can craft using in-game elements and resources. Each asset has unique crafting requirements and associated benefits outlined within the Craft Book.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9" className="mb-2">
                  <Accordion.Header>How can I craft a Liquidity Node?</Accordion.Header>
                  <Accordion.Body>
                  <p className='justify'>
                  To craft a Liquidity Node, start by purchasing a Level 1 Liquidity Node. The initial cost of each Liquidity Node is 100 WaveCoin, and with every 210,000 nodes created, the price doubles. A total of 21 million Liquidity Nodes are available. For higher-level Liquidity Nodes, gather the necessary components listed in the Craft Book. Once crafted, a Liquidity Node can provide benefits such as additional WaveCoin tokens, higher referral bonuses, and enhanced airdrop rewards. Details of these benefits can be found in the Craft Book. 
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10" className="mb-2">
                  <Accordion.Header>What is the purpose of Liquidity Nodes crafting?</Accordion.Header>
                  <Accordion.Body>
                  <p className='justify'>
                  Purchasing Liquidity Nodes directly supports the liquidity required for trading WaveCoin tokens on decentralized exchanges (DEX). Crafting involves combining Liquidity Nodes and in-game elements to create new, more powerful assets, enhancing the user's experience and interaction within the WaveSwaps ecosystem.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11" className="mb-2">
                  <Accordion.Header>What are the benefits of higher-level Liquidity Nodes?</Accordion.Header>
                  <Accordion.Body>
                  <p className='justify'>
                  Higher-level Liquidity Nodes offer greater daily {SETTINGS.tokenSymbol} rewards, increased referral bonuses, and stronger airdrop powers. These higher-level assets amplify your benefits and overall influence within the platform.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="12" className="mb-2">
                  <Accordion.Header>What role do PowerCores play in the ecosystem?</Accordion.Header>
                  <Accordion.Body>
                  <p className='justify'>
                  PowerCores contribute to the WaveSwaps Airdrop pool. As the community purchases more PowerCores, the liquidity pool grows, increasing the overall value and rewards available to participants.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="13" className="mb-2">
                  <Accordion.Header>What is Airdrop Power (AP)?</Accordion.Header>
                  <Accordion.Body>
                  <p className='justify'>
                  Airdrop Power (AP) is a measure of your eligibility for airdrops within the platform. The higher your AP, the better your chances of receiving larger or more frequent airdrops.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default FAQs;