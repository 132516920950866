import React, { useState } from 'react';
import { Button, Image, Modal, Badge, Row, Col } from 'react-bootstrap';
import logoToken from '../logoToken.svg';
import SETTINGS from '../SETTINGS';

const CraftBook = () => {
    const [showModal, setShowModal] = useState(false);
    const [nodeInfoData, setNodeInfoData] = useState([]);

    // Define the specific node keys you want to display
    const displayedNodeKeys = ['1', '2', '3', '4', '5', '6','70']; // Example keys, adjust these as needed

    const handleButtonClick = () => {
        const nodeInfoArray = Object.entries(SETTINGS.NODE_INFO)
            .filter(([key]) => displayedNodeKeys.includes(key)) // Filter nodes based on the keys you want to display
            .map(([_, value]) => value); // Get the values (node objects) after filtering
        setNodeInfoData(nodeInfoArray);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <div className="text-center">
            <Button onClick={handleButtonClick} className='buton-secondary primary buton btn' style={{ maxWidth: "300px" }}>
                View Craft Book
            </Button>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                    <h3 className='mb-0'>Craft Book</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {nodeInfoData.map((node, index) => (
                        <div className='pokemon-card mb-3 p-3 chat-message' key={index}>
                            <Row className="align-items-center justify-content-center">

                                {/* Nested Row for 1st, 2nd and 3rd columns, centered vertically */}
                                <Col xs={12} className='d-flex flex-column align-items-center'>
                                    <Row className="w-100">

                                        {/* 1st Column: Image */}
                                        <Col sm={4} xs={12} className='d-flex justify-content-center mb-3'>
                                            <Image src={node.imgUrl} rounded className="w-100" style={{ maxWidth: '300px' }} />
                                        </Col>

                                        {/* 2nd Column: Price / Items needed */}
                                        <Col sm={4} xs={12} className='d-flex flex-column justify-content-start mb-3 align-items-left text-left text-md-start'>
                                            <h5 className='text-uppercase mb-md-3 mb-1'>{node.title}</h5>
                                            {node.price > 0 ? (
                                                <div className='mt-sm-0'>
                                                    <span className="mb-3"><small>Craft with: </small></span>
                                                    <h6>
                                                        {node.craftElements.map((imgMod, index1) => {
                                                            const craftElement = Object.values(SETTINGS.NODE_INFO).find(node => node.imgUrl === imgMod);
                                                            return (
                                                                <Image 
                                                                    key={index1} 
                                                                    src={imgMod} 
                                                                    rounded 
                                                                    className="me-1 mb-md-0 mt-3" 
                                                                    style={{ width: '50px', height: '50px' }} 
                                                                    title={craftElement?.hoverText}
                                                                />
                                                            );
                                                        })}
                                                    </h6>
                                                </div>
                                            ) : (
                                                <div className='mt-sm-0 mt-2 mb-lg-0 mb-3'>
                                                    <p><small>Craft with: </small></p>
                                                    <h6>
                                                        {node.craftElements.map((imgMod, index1) => {
                                                            const craftElement = Object.values(SETTINGS.NODE_INFO).find(node => node.imgUrl === imgMod);
                                                            return (
                                                                <Image 
                                                                    key={index1} 
                                                                    src={imgMod} 
                                                                    rounded 
                                                                    className="me-1 mb-md-0 mt-1" 
                                                                    style={{ width: '40px', height: '40px' }} 
                                                                    title={craftElement?.hoverText}
                                                                />
                                                            );
                                                        })}
                                                    </h6>
                                                </div>
                                            )}
                                        </Col>

                                        {/* 3rd Column: Parameters */}
                                        <Col sm={4} xs={12} className='d-flex flex-column justify-content-start align-items-center text-center text-md-end'>
                                            <Row className='w-100 mt-lg-0 mt-2 mb-1'>
                                                <Col xs={12}>
                                                    <div className='d-block mb-2'>
                                                        <small> </small> <strong>{node.lifespan} days</strong>
                                                    </div>
                                                </Col>
                                                <Col xs={12}>
                                                    <div className='d-block mb-2'>
                                                        <small><strong>{node.dailyRewards.toFixed(2)} WAVE/day</strong></small>
                                                    </div>
                                                </Col>
                                                <Col xs={12}>
                                                    <div className='d-block mb-2'>
                                                        <small>Referral: </small> <strong>{node.refferalPercent} %</strong>
                                                    </div>
                                                </Col>
                                                <Col xs={12}>
                                                    <div className='d-block mb-2'>
                                                        <small>AP power: </small> <strong>{node.airdrop_power}</strong>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    ))}
                </Modal.Body>

                <Modal.Footer className='justify-content-center mt-1'>
                    <Button variant="buton" className="buton" onClick={handleClose} style={{ maxWidth: "400px" }}>
                    Close
                    </Button>
                </Modal.Footer>
                </Modal>


        </div>
    );
};

export default CraftBook;